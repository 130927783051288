import { render } from "./index.vue?vue&type=template&id=498142aa"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "498142aa"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('498142aa', script)) {
    api.reload('498142aa', script)
  }
  
  module.hot.accept("./index.vue?vue&type=template&id=498142aa", () => {
    api.rerender('498142aa', render)
  })

}

script.__file = "src/views/index/index.vue"

export default script